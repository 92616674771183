<template>
  <v-app>
    <v-main>
      <nav-bar v-if="$route.path !== '/login'"></nav-bar>
      <top-header v-if="$route.path !== '/login'"></top-header>
      <global-snack-bar></global-snack-bar>
      <div class="mr-16 pr-16 mb-8 mt-16 ml-4">
        <router-view/>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import globalSnackBar from "@/components/notification/globalSnackBar";
import navBar from "@/components/navbar/navBar";
import TopHeader from "@/components/navbar/topHeader";

export default {
  name: 'App',
  components: {
    TopHeader,
    navBar,
    globalSnackBar
  },
  data: () => ({
    //
  }),
  methods: {
    getProfile() {
      window.axios.get('/admin/auth/profile').then((res) => {
        this.$store.state.user = res.data.data.user
        this.$store.state.auth = true
        this.$store.state.loading = false
      }).catch((err) => {
        if (err.response.status === 401 && this.$route.path !== '/login') {
          this.$router.push('/login')
        }
      })
    },
  },
  mounted() {
    this.getProfile()
  }
};
</script>
<style>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../src/assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

* {
  font-family: Blinker-SemiBold, Blinker-SemiBold, serif;
}
</style>
