<template>
  <div class="top-header">
    <!--    uploadProgress-->
    <v-progress-circular
        v-if="progressStatus"
        :rotate="360"
        :size="100"
        :width="20"
        :value="progressPercentage"
        color="success"
        style="position:fixed!important ; z-index: 10 ; top:35vh!important ; left:47vw!important;"
    >
      {{ progressPercentage }} %
    </v-progress-circular>
    <v-btn elevation="0" fab large class="mx-4 mt-2 mb-8" color="#39bf88" @click="uploadFileDialog = true;url=null">
      <v-icon color="white">mdi-paperclip</v-icon>
    </v-btn>
    <v-dialog width="60%" v-model="uploadFileDialog" overlay-opacity="0.2">
      <v-card style="overflow: hidden!important">
        <v-card-actions>
          <v-icon @click="uploadFileDialog = !uploadFileDialog" color="error">mdi-close</v-icon>
        </v-card-actions>
        <v-row>
          <v-col cols="12" class="mx-16">
            <v-file-input v-model="file" color="black" label="Select file"></v-file-input>
          </v-col>
          <v-col cols="12" class="text-center mb-5 mt-2" v-if="!url">
            <v-btn class="white--text" color="#39bf88" width="250px" @click="upload">upload</v-btn>
          </v-col>
          <v-col cols="12" class="text-center mb-5 mt-2" v-else>
            {{ url }}
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "topHeader",
  data() {
    return {
      progressStatus: false,
      progressPercentage: 1,
      uploadFileDialog: false,
      file: null,
      url: null
    }
  },
  methods: {
    upload() {
      let formData = new FormData()
      formData.append('file', this.file)
      window.axios.post('admin/upload-file/initial', formData, {
        onUploadProgress: function (progressEvent) {
          this.progressPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        }.bind(this)
      }).then((res) => {
        this.url = res.data.data
        this.progressStatus = false
        this.progressPercentage = 0
      }).catch((err) => {
        this.progressStatus = false
        this.progressPercentage = 0
        if (err.response.status === 422) {
          this.showValidationErrors(err.response.data.errors)
        } else {
          this.openSnackbar(err.response.data.message, 'error')
        }
      })
    }
  }
}
</script>

<style scoped>
.top-header {
  height: 4vh !important;
}
</style>