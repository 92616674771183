<template>
  <div>
    <v-dialog
        multi-line
        width="500px"
        class="mt-16"
        centered
        top
        v-model="$store.state.snackbarStatus"
    >
      <v-card :color="$store.state.snackbarColor !== 'error' && $store.state.snackbarColor !== '#d40d45' ? '#39bf88' : '#fdbd68'" style="border-radius: 20px!important">
        <v-card-actions>
          <v-icon @click="closeDialog"
                  :color="$store.state.snackbarColor !== 'error' && $store.state.snackbarColor !== '#d40d45' ? 'error' : 'white'">
            mdi-close
          </v-icon>
        </v-card-actions>
        <v-card-text class="py-6">
          <v-row class="ma-0 pa-0">
            <v-col v-html="$store.state.snackbarText "
                   style="color:white">
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "globalSnackBar",
  data() {
    return {
      timeout: 3800
    }
  },
  watch: {
    '$store.state.snackbarStatus'(value) {
      if (value) {
        let self = this
        window.setTimeout(function () {
          self.$store.commit('setSnackbarStatus', false)
        }, self.timeout)
      }
    }
  },
  methods: {
    closeDialog() {
      this.$store.commit('setSnackbarStatus', false)
    }
  }
}
</script>

<style scoped>
>>> .v-dialog {
  box-shadow: unset !important;
}
</style>
