<template>
  <v-navigation-drawer
      class="side-bar"
      color="#39bf88"
      width="20vw"
      right
      mini-variant-width="5vw"
      permanent
      expand-on-hover
      fixed
      v-model="drawer"
      flat
  >
    <v-list
        nav
    >
      <v-list-item @click="$router.push('/')">
        <v-list-item-title class="menu-text">
          <img width="100%" src="../../assets/logo.svg" alt="LOGO">
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/questions/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-tooltip-question-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Questions
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/exams/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-file-question-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Exam
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/admins/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-account-star-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Admins
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/users/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Users
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/plans/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-receipt-text</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Plans
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/categories/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-lan</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Categories
        </v-list-item-title>
      </v-list-item>
<!--      <v-list-item @click="$router.push('/exam/index')">-->
<!--        <v-list-item-icon>-->
<!--          <v-icon color="#fdbd68" x-large>mdi-text-box-multiple-outline</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-title class="menu-text">-->
<!--          Exams-->
<!--        </v-list-item-title>-->
<!--      </v-list-item>-->
      <v-list-item @click="$router.push('/blog/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-post-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Resources
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/setting/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Site setting
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/classes/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-google-classroom</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Classes
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/transactions/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-currency-usd</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Transactions
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/orders/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-cart-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Orders
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/reports/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-poll</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Reports
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/products/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-bookshelf</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Products
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/statics/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-book-open-page-variant-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Statics
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/bar-news/index')">
        <v-list-item-icon>
          <v-icon color="#fdbd68" x-large>mdi-newspaper</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          Bar News
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  name: "navBar",
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    goTo() {
    },
    logout() {

    }
  }
}
</script>

<style scoped>
.menu-text {
  color: white !important;
  font-size: 30px !important;
}
</style>