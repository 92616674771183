import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import( '../views/Main/IndexView')
    },
    {
        path: '/login',
        name: 'LoginView',
        component: () => import( '../views/Auth/LoginView')
    },

    {
        path: '/questions/index',
        name: 'QuestionIndexView',
        component: () => import( '../views/Question/QuestionIndexView')
    },

    {
        path: '/questions/edit/:id',
        name: 'QuestionEditView',
        component: () => import( '../views/Question/QuestionEditView')
    },

    {
        path: '/questions/create',
        name: 'QuestionCreateView',
        component: () => import( '../views/Question/QuestionCreateView')
    },

    {
        path: '/exams/index',
        name: 'ExamIndexView',
        component: () => import( '../views/Exam/ExamIndexView')
    },

    {
        path: '/exams/edit/:id',
        name: 'ExamEditView',
        component: () => import( '../views/Exam/ExamEditView')
    },

    {
        path: '/exams/create',
        name: 'ExamCreateView',
        component: () => import( '../views/Exam/ExamCreateView')
    },


    {
        path: '/admins/index',
        name: 'AdminIndexView',
        component: () => import( '../views/Admin/AdminIndexView')
    },

    {
        path: '/admins/create',
        name: 'AdminCreateView',
        component: () => import( '../views/Admin/AdminCreateView')
    },

    {
        path: '/admins/edit/:id',
        name: 'AdminEditView',
        component: () => import( '../views/Admin/AdminEditView')
    },

    {
        path: '/users/index',
        name: 'UserIndexView',
        component: () => import( '../views/User/UserIndexView')
    },

    {
        path: '/users/create',
        name: 'UserCreateView',
        component: () => import( '../views/User/UserCreateView')
    },

    {
        path: '/users/edit/:id',
        name: 'UserEditView',
        component: () => import( '../views/User/UserEditView')
    },

    {
        path: '/plans/index',
        name: 'PlanIndexView',
        component: () => import( '../views/Plan/PlanIndexView')
    },

    {
        path: '/plans/create',
        name: 'PlanCreateView',
        component: () => import( '../views/Plan/PlanCreateView')
    },

    {
        path: '/plans/edit/:id',
        name: 'PlanEditView',
        component: () => import( '../views/Plan/PlanEditView')
    },

    {
        path: '/categories/index',
        name: 'CategoryIndexView',
        component: () => import( '../views/Category/CategoryIndexView')
    },

    {
        path: '/categories/create',
        name: 'CategoryCreateView',
        component: () => import( '../views/Category/CategoryCreateView')
    },

    {
        path: '/categories/edit/:id',
        name: 'CategoryEditView',
        component: () => import( '../views/Category/CategoryEditView')
    },

    {
        path: '/blog/index',
        name: 'BlogIndexView',
        component: () => import( '../views/Blog/BlogIndexView')
    },

    {
        path: '/blog/create',
        name: 'BlogCreateView',
        component: () => import( '../views/Blog/BlogCreateView')
    },

    {
        path: '/blog/edit/:id',
        name: 'BlogEditView',
        component: () => import( '../views/Blog/BlogEditView')
    },


    {
        path: '/bar-news/index',
        name: 'BarNewsIndexView',
        component: () => import( '../views/BarNews/BarNewsIndexView')
    },

    {
        path: '/bar-news/create',
        name: 'BarNewsCreateView',
        component: () => import( '../views/BarNews/BarNewsCreateView')
    },

    {
        path: '/bar-news/edit/:id',
        name: 'BarNewsEditView',
        component: () => import( '../views/BarNews/BarNewsEditView')
    },

    {
        path: '/setting/index',
        name: 'SettingIndexView',
        component: () => import( '../views/Setting/SettingIndexView')
    },

    {
        path: '/classes/index',
        name: 'ClassIndexView',
        component: () => import( '../views/Classes/ClassIndexView')
    },

    {
        path: '/classes/create',
        name: 'ClassCreateView',
        component: () => import( '../views/Classes/ClassCreateView')
    },

    {
        path: '/classes/edit/:id',
        name: 'ClassEditView',
        component: () => import( '../views/Classes/ClassEditView')
    },

    {
        path: '/classes/meeting/index/:clasId',
        name: 'MeetingIndexView',
        component: () => import( '../views/Classes/Meeting/MeetingIndexView')
    },

    {
        path: '/classes/meeting/create/:clasId',
        name: 'MeetingCreateView',
        component: () => import( '../views/Classes/Meeting/MeetingCreateView')
    },

    {
        path: '/classes/permission/index/:clasId',
        name: 'PermissionIndexView',
        component: () => import( '../views/Classes/Permission/PermissionIndexView')
    },
    {
        path: '/classes/permission/create/:clasId',
        name: 'PermissionCreateView',
        component: () => import( '../views/Classes/Permission/PermissionCreateView')
    },

    {
        path: '/transactions/index',
        name: 'TransactionIndexView',
        component: () => import( '../views/Transaction/TransactionIndexView')
    },

    {
        path: '/orders/index',
        name: 'OrderIndexView',
        component: () => import( '../views/Order/OrderIndexView')
    },

    {
        path: '/reports/index',
        name: 'ReportIndexView',
        component: () => import( '../views/Report/ReportIndexView')
    },

    {
        path: '/products/index',
        name: 'ProductIndexView',
        component: () => import( '../views/Product/ProductIndexView')
    },
    {
        path: '/products/animations/index',
        name: 'AnimationIndexView',
        component: () => import( '../views/Product/Animation/AnimationIndexView')
    },
    {
        path: '/products/animations/create',
        name: 'AnimationCreateView',
        component: () => import( '../views/Product/Animation/AnimationCreateView')
    },
    {
        path: '/products/animations/edit/:id',
        name: 'AnimationEditView',
        component: () => import( '../views/Product/Animation/AnimationEditView')
    },
    {
        path: '/products/videos/index',
        name: 'VideoIndexView',
        component: () => import( '../views/Product/Video/VideoIndexView')
    },
    {
        path: '/products/videos/create',
        name: 'VideoCreateView',
        component: () => import( '../views/Product/Video/VideoCreateView')
    },
    {
        path: '/products/videos/edit/:id',
        name: 'VideoEditView',
        component: () => import( '../views/Product/Video/VideoEditView')
    },
    {
        path: '/products/books/index',
        name: 'BookIndexView',
        component: () => import( '../views/Product/Book/BookIndexView')
    },
    {
        path: '/products/books/create',
        name: 'BookCreateView',
        component: () => import( '../views/Product/Book/BookCreateView')
    },
    {
        path: '/products/books/edit/:id',
        name: 'BookEditView',
        component: () => import( '../views/Product/Book/BookEditView')
    },
    {
        path: '/statics/index',
        name: 'StaticIndexView',
        component: () => import( '../views/Static/StaticViewIndex')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
