import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'

import CKEditor from 'ckeditor4-vue';

Vue.use( CKEditor );


Vue.mixin({
    methods: {
        openSnackbar(text, color = '#696CFF') {
            this.$store.commit('setSnackbarStatus', true)
            this.$store.commit('setSnackbarText', text)
            this.$store.commit('setSnackbarColor', color)
        },
        showValidationErrors(errors) {
            let errorText = ''
            Object.entries(errors).forEach((err) => {
                err[1].forEach((item) => {
                    errorText = errorText + item + '<br>'
                })
            })
            this.openSnackbar(errorText, '#d40d45')
        },
        objectParametersToString(params) {
            let stringedParams = '';
            Object.entries(params).forEach((param) => {
                if (param[1]) {
                    if (Array.isArray(param[1]) && param[1].length) {
                        param[1].forEach((item) => {
                            stringedParams = stringedParams + '&' + param[0] + '[]' + '=' + item
                        })
                    } else {
                        stringedParams = stringedParams + '&' + param[0] + '=' + param[1]
                    }
                }
            })
            return stringedParams;
        }
    },
})

Vue.config.productionTip = false

new Vue({
    store,
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
